<template>
  <div class="login-form mainDashboard disableYPadding">
    <div class="section mt-5">
      <img
          :src="require('@/assets/img/logo/cca-full-white-logo-md.png')"
          alt="cca_logo"
          class="loginLogo pointer-event"
          @click="$router.push({ name: 'Dashboard' })"
      />
      <!--<h2 class="mt-2 text-white">Bejelentkezés</h2>-->
    </div>
    <div class="section mt-5">
      <!-- <h1>Bejelentkezés</h1>
      <h4>Add meg adataidat a belépéshez</h4> -->
      <div v-if="errors && errors.message">
        <p class="alert alert-danger">{{ errors.message }}</p>
      </div>
    </div>
    <div v-if="showForm" class="section mt-3 mb-5">
      <form id="form1">
        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
                id="email1"
                v-model="form.email"
                :class="{ 'is-invalid': errors.email }"
                class="form-control"
                name="email"
                placeholder="Email cím"
                type="email"
            />
            <div v-if="errors.email" class="invalid-feedback">
              {{ errors.email[0] }}
            </div>
          </div>

          <div class="text-left">
            <small class="text-danger"></small>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
                id="password1"
                v-model="form.password"
                class="form-control"
                name="password"
                placeholder="Jelszó"
                type="password"
            />
            <i class="clear-input">
              <ion-icon name="close-circle"></ion-icon>
            </i>
          </div>

          <div class="text-left">
            <small class="text-danger"></small>
          </div>
        </div>

        <div class="form-links mt-2">
          <div>
            <button
                class="btn btn-magenta btn-sm"
                @click="$router.push({ name: 'Registration' })"
            >
              Regisztráció
            </button>
          </div>
          <div>
            <a
                class="text-white"
                href="#"
                @click="$router.push({ name: 'PasswordRecovery' })"
            >Jelszó visszaállítás</a
            >
          </div>
        </div>

        <div class="form-button-group">
          <div class="btn btn-blue btn-block btn-lg" @click="goLogin">
            Bejelentkezés
          </div>
        </div>
      </form>
    </div>
    <div v-if="!showForm" class="form-button-group mb-3">
      <div class="btn btn-blue btn-block btn-lg" @click="showForm = true">
        Bejelentkezés
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Home",
  components: {},
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    deviceToken: "",
    deviceManufacturer: "",
    errors: {},
    showForm: true,
  }),
  methods: {
    goLogin() {
      this.errors = "";

      axios
          .post(this.$store.getters.getApiUrl + "login", {
            email: this.form.email,
            password: this.form.password,
            deviceToken: localStorage.getItem("deviceToken"),
            deviceManufacturer: localStorage.getItem("deviceManufacturer"),
          })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("loginSuccess", {
                user: response.data.user,
                shop: response.data.shop,
              });

              //Save email & password to localStorage
              localStorage.loginEmail = this.$data.form.email;
              localStorage.loginPassword = this.$data.form.password;
              localStorage.userId = response.data.user.id;

              this.$router.push({name: "Dashboard"});
            }
          })
          .catch((error) => {
            console.log("hiba: ", error.response.data);
            this.errors = error.response.data;
          });
    },
  },
  mounted() {
    if (localStorage.loginEmail && localStorage.loginPassword) {
      if (localStorage.loginEmail !== "null") {
        this.form.email = localStorage.loginEmail;
        this.form.password = localStorage.loginPassword;
        this.deviceToken = localStorage.deviceToken;
        this.deviceManufacturer = localStorage.deviceManufacturer;

        this.goLogin();
      }
    }

    //Add bg class to body
    document.body.classList.add("loginBg");
  },
  destroyed() {
    document.body.classList.remove("loginBg");
  },
};
</script>

<style>
</style>
