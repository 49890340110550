import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/style.css";
import "@/assets/css/calendar.css";
// import "vue-material-design-icons/styles.css";
import Toasted from "vue-toasted";
import "vue2-animate/dist/vue2-animate.min.css";

import "vue-datetime/dist/vue-datetime.css";
import { Datetime } from "vue-datetime";

import VCalendar from "v-calendar";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import GmapVue from 'gmap-vue';

// import "material-icons/iconfont/material-icons.scss";
// import "@fortawesome/fontawesome-free/css/all.css";
// import "@fortawesome/fontawesome-free/js/all.js";
// import "@/assets/js/lib/jquery-3.4.1.min.js";
// import "@/assets/js/base.js";


//Toaster config
Vue.use(Toasted, {
  iconPack: "material"
});

//v-Calendar config
Vue.use(VCalendar, {
  componentPrefix: "vc",
  headWeekDay: ["H", "K", "Sze", "Cs", "P", "Szo", "V"]
});

// Datetime plugin config
Vue.use(Datetime);
Vue.component("datetime", Datetime);

//ION Icon config
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: 'AIzaSyAPGRAQ_wonbDnFxF7hk3s-HwiPXUX-2dI',
    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: 'places, marker',

    // [OPTIONAL] If you want to set the version, you can do so:
    // v: '3.26',

    // This option was added on v3.0.0 but will be removed in the next major release.
    // If you already have an script tag that loads Google Maps API and you want to use it set you callback
    // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
    // to the `window` object, is the only requirement.
    // customCallback: 'MyCustomCallback',
  },

  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: false,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
