import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiURL: "https://cca-api.fixeet.com/api/",
    // apiURL: "https://fixeetapi.molnaristvan.com/api/",
    // apiURL: "http://ccaauto-api.local/api/",
    // apiURL: "http://192.168.10.202:8000/api/",
    loggedIn: false,
    headerState: true,
    user: {
      name: "Vendég",
      email: "",
      phone: "",
      profileImage: "",
      darkMode: false,
      type: 0,
      token: null,
      id: null,
      private_calendar: false,
      reminderTime: null,
      reminderUnit: null,
      zip: "",
      city: "",
      address: "",
      email_verified: 0
    },
    shop: {
      name: "",
      phone: "",
      email: "",
      state: 1,
      profileImage: "",
      introduction: "",
      zip: null,
      city: "",
      address: "",
      qr_code: ""
    },
    error: {
      message: "",
      show: false
    },
    registration: {
      step: 0,
      type: null,
      name: "",
      password: "",
      email: "",
      phone: "",
      address: "",
      zip: "",
      city: "",
      profileImage: require("@/assets/img/profile.jpg"),
      shopName: "",
      shopPhone: "",
      shopEmail: ""
    },
    menu: {
      show: false
    },
    currentCategoryId: 0,
    registrationCategories: "",
    registrationChoosedCategories: [],
    registrationServices: [],
    counters: {
      appointments: null,
      conversations: null,
      notifications: null,
      contacts: null
    },
    calendarChoosedDate: null
  },
  mutations: {
    addErrorMessage: (state, payload) => {
      this.state.error.message = payload.message;
      this.state.error.show = true;

      setTimeout(() => {
        this.state.error.message = "";
      }, payload.timeout);
    },
    loginSuccess: (state, payload) => {
      state.user = payload.user;
      state.shop = payload.shop;
      localStorage.token = payload.user.token;
      localStorage.currentUser = JSON.stringify(payload.user);
      state.loggedIn = true;
      state.menu.show = false; //Close the menu
    },
    updateLoginStatus: (state, payload) => {
      state.loggedIn = payload.loggedIn;
    },
    refreshToken(state, payload) {
      state.user.token = payload.token;
      localStorage.token = payload.token;
      state.isLoggedIn = payload.loggedIn;
      localStorage.currentUser = JSON.stringify(payload.user);
    },
    setUserData: (state, payload) => {
      state.user = payload.user;
    },
    updateCustomerRegistrationStep1: (state, payload) => {
      state.registration.name = payload.registration.name;
      state.registration.email = payload.registration.email;
      state.registration.password = payload.registration.password;
    },
    updateServiceProviderRegistrationStep1: (state, payload) => {
      state.registration.name = payload.registration.name;
      state.registration.email = payload.registration.email;
      state.registration.password = payload.registration.password;
      state.registration.phone = payload.registration.phone;
    },
    updateMenu: state => {
      state.menu.show = !state.menu.show;
    },
    closeMenu: state => {
      state.menu.show = false;
    },
    updateDarkMode: state => {
      state.user.darkMode = !state.user.darkMode;
    },
    updateRegistrationCategories: (state, payload) => {
      state.registrationCategories = payload.categories;
    },
    updateRegistrationChoosedCategories: (state, payload) => {
      state.registrationChoosedCategories = payload.categories;
    },
    updateRegistrationServices: (state, payload) => {
      state.registrationServices = payload.services;
    },
    updateCurrentCategoryId: (state, payload) => {
      state.currentCategoryId = payload.currentCategoryId;
    },
    updateCounters: (state, payload) => {
      state.counters.notifications = payload.notifications;
      state.counters.appointments = payload.appointments;
      state.counters.conversations = payload.conversations;
      state.counters.contacts = payload.contacts;
    },
    updateCalendarChoosedDate: (state, payload) => {
      state.calendarChoosedDate = payload.calendarChoosedDate;
    },
    updateHeaderState: (state, payload) => {
      state.headerState = payload.headerState;
    }
  },
  actions: {
    updateMenuState({ commit }) {
      commit("updateMenu");
    },
    closeMenu({ commit }) {
      commit("closeMenu");
    },
    updateDarkModeState({ commit }) {
      commit("updateDarkMode");
    },
    updateCurrentCategoryId({ commit, payload }) {
      commit("updateCurrentCategoryId", payload);
    },
  },
  getters: {
    getApiUrl(state) {
      return state.apiURL;
    },
    getLoggedIn(state) {
      return state.loggedIn;
    },
    loggedIn(state) {
      return state.loggedIn;
    },
    getToken(state) {
      return state.user.token;
    },
    getUserData(state) {
      return state.user;
    },
    user(state) {
      return state.user;
    },
    userData(state) {
      return state.user;
    },
    shopData(state) {
      return state.shop;
    },
    getRegistrationData(state) {
      return state.registration;
    },
    getMenuState(state) {
      return state.menu.show;
    },
    getDarkModeState(state) {
      return state.user.darkMode;
    },
    getRegistrationCategories(state) {
      return state.registrationCategories;
    },
    getRegistrationChoosedCategories(state) {
      return state.registrationChoosedCategories;
    },
    getRegistrationServices(state) {
      return state.registrationServices;
    },
    getCurrentCategoryId(state) {
      return state.currentCategoryId;
    },
    notificationsCounter(state) {
      return state.counters.notifications;
    },
    contactCounter(state) {
      return state.counters.contacts;
    },
    calendarChoosedDate(state) {
      return state.calendarChoosedDate;
    },
    headerState(state) {
      return state.headerState;
    }
  },
  modules: {}
});
