<template>
  <div>
    <!--<Auth />-->

    <Header
      title="Felhasználók"
      :muted="shopData.name"
      back-route-name="Shop"
      custom-class="bg-active text-light"
    />

    <div class="fab-button animate bottom-right pb-5 mb-2">
      <a
        href="javascript:void(0)"
        @click="showNewUserModal = true"
        class="fab"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <ion-icon
          name="add-outline"
          role="img"
          class="md hydrated"
          aria-label="add outline"
        ></ion-icon>
      </a>
    </div>

    <div v-if="users && users.length > 0" class="section inset">
      <div
        class="wide-block bg-white bg-transparent-95 pt-2 pb-2 mt-1"
        v-for="(user, index) in users"
        :key="index"
        href="javascript:void(0)"
        @click="editUser(user)"
      >
        <div class="row">
          <div class="col-3">
            <div class="avatar">
              <div
                v-if="user.profileImage"
                :style="{
                  backgroundImage: 'url(' + user.profileImage + ')',
                }"
                class="profileImage"
              ></div>
              <div
                v-else
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/img/profile.jpg') + ')',
                }"
                class="profileImage"
              ></div>
            </div>
          </div>
          <div class="col-9">
            {{ user.name }}

            <div
              class="d-inline-block text-primary text-right float-right"
              style="font-size: 2rem"
              y
            >
              <div
                class="icon-custom icon-chevron-forward-circle-outline"
              ></div>
            </div>

            <div class="content-footer mt-1">
              <div class="row">
                <div class="col-6 text-black-50">
                  <div v-if="user.type == 1">
                    <span class="badge badge-success">ADMIN</span>
                  </div>
                  <div v-else>
                    <span class="badge badge-blue">ALKALMAZOTT</span>
                  </div>
                  <div class="mt-2">
                    Tel: {{ user.phone }} <br />
                    Email: {{ user.email }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="section full">
      <ErrorPanel
        title="Hoppá!"
        class-label="alert alert-primary mainGreen"
        message="Nincsenek megjeleníthető felhasználók!"
        message2=""
      />
    </div>

    <Nav />
    <NavFooter />

    <!-- Modals -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    >
      <div
        v-if="showEditUserModal"
        class="modal fade panelbox panelbox-right show"
        id="PanelRight"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-white">Fiók módosítása</h4>
              <a
                href="javascript:;"
                @click="showEditUserModal = false"
                data-dismiss="modal"
                class="panel-close"
              >
                <!-- <ion-icon name="close-outline" role="img" class="md hydrated" aria-label="close outline"></ion-icon> -->
                <div class="icon-custom icon-close-circle-white"></div>
              </a>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <p class="">
                    Beállíthatod a felhasználó jogosultságát, <br />
                    vagy eltávolíthatod az üzletedből.
                  </p>
                </div>
                <div v-if="currentUser" class="col-12">
                  <div class="row my-3">
                    <div class="col-8">
                      <div class="ml-1">
                        <strong>Admin jogosultság</strong> <br />
                        <div class="settingsDescription">
                          Bekapcsolás esetén szerkesztheti az üzlet adatait.
                        </div>
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          v-model="currentUser.type"
                          id="type"
                        />
                        <label class="custom-control-label" for="type"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-8 offset-2">
                  <div
                    @click="updateUser"
                    class="btn btn-success btn-md btn-block my-2"
                  >
                    Mentés
                  </div>
                </div>
                <div class="col-8 offset-2">
                  <div
                    @click="deleteUser"
                    class="btn btn-danger btn-md btn-block my-2"
                  >
                    Eltávolítás
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    >
      <div
        v-if="showNewUserModal"
        class="modal fade panelbox panelbox-right show"
        id="PanelRight"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-white">Fiók létrehozása</h4>
              <a
                href="javascript:;"
                @click="showNewUserModal = false"
                data-dismiss="modal"
                class="panel-close"
              >
                <!-- <ion-icon name="close-outline" role="img" class="md hydrated" aria-label="close outline"></ion-icon> -->
                <div class="icon-custom icon-close-circle-white"></div>
              </a>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="row my-3">
                    <div class="col-8">
                      <div class="ml-1">
                        <strong>Admin jogosultság</strong> <br />
                        <div class="settingsDescription">
                          Bekapcsolás esetén szerkesztheti az üzlet adatait.
                        </div>
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          v-model="newUser.type"
                          id="newUserType"
                        />
                        <label
                          class="custom-control-label"
                          for="newUserType"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label for="newUser.name">Név</label>
                      <input
                        type="text"
                        class="form-control"
                        name="newUser.name"
                        id="newUser.name"
                        v-model="newUser.name"
                        :class="{ 'is-invalid': errors.name }"
                      />
                      <div class="invalid-feedback" v-if="errors.name">
                        {{ errors.name[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label for="newUser.email">Email cím</label>
                      <input
                        type="text"
                        class="form-control"
                        name="newUser.email"
                        id="newUser.email"
                        v-model="newUser.email"
                        :class="{ 'is-invalid': errors.email }"
                      />
                      <div class="invalid-feedback" v-if="errors.email">
                        {{ errors.email[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label for="newUser.phone">Telefonszám</label>
                      <input
                        type="text"
                        class="form-control"
                        name="newUser.phone"
                        id="newUser.phone"
                        v-model="newUser.phone"
                        :class="{ 'is-invalid': errors.phone }"
                      />
                      <div class="invalid-feedback" v-if="errors.phone">
                        {{ errors.phone[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label for="newUser.password">Jelszó</label>
                      <input
                        type="password"
                        class="form-control"
                        name="newUser.password"
                        id="newUser.password"
                        v-model="newUser.password"
                        :class="{ 'is-invalid': errors.password }"
                      />
                      <div class="invalid-feedback" v-if="errors.password">
                        {{ errors.password[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label for="newUser.jobDescription"
                        >Munkakör megnevezés</label
                      >
                      <textarea
                        type="text"
                        class="form-control"
                        name="newUser.jobDescription"
                        id="newUser.jobDescription"
                        v-model="newUser.jobDescription"
                        :class="{ 'is-invalid': errors.jobDescription }"
                        rows="5"
                      ></textarea>
                      <div
                        class="invalid-feedback"
                        v-if="errors.jobDescription"
                      >
                        {{ errors.jobDescription[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-8 offset-2">
                  <div
                    @click="createUser"
                    class="btn btn-success btn-md btn-block my-2"
                  >
                    Mentés
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div
      v-if="showEditUserModal || showNewUserModal"
      class="modal-backdrop fade show"
    ></div>
  </div>
</template>

<script>
import Header from "../partials/Header";
// import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
import { mapGetters } from "vuex";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ShopUsers",
  components: {
    ErrorPanel,
    // Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    users: {},
    showEditUserModal: false,
    showNewUserModal: false,
    currentUser: null,
    newUser: {
      name: "",
      email: "",
      phone: "",
      jobDescription: "",
      password: "",
      type: false,
    },
  }),
  methods: {
    getShopUsers() {
      axios
        .post(this.$store.getters.getApiUrl + "shop/users", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.users;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    editUser(user) {
      this.currentUser = user;
      this.showEditUserModal = true;
    },
    updateUser() {
      axios
        .post(this.$store.getters.getApiUrl + "shop/users/update", {
          token: this.$store.getters.getToken,
          userId: this.currentUser.id,
          type: this.currentUser.type,
        })
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.users;
            this.showEditUserModal = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    createUser() {
      axios
        .post(this.$store.getters.getApiUrl + "shop/users/store", {
          token: this.$store.getters.getToken,
          name: this.newUser.name,
          type: this.newUser.type,
          phone: this.newUser.phone,
          email: this.newUser.email,
          password: this.newUser.password,
          job_description: this.newUser.jobDescription,
        })
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.users;

            this.newUser.name = "";
            this.newUser.type = "";
            this.newUser.phone = "";
            this.newUser.email = "";
            this.newUser.password = "";
            this.newUser.jobDescription = "";

            this.showNewUserModal = false;

            //Toast success message
            this.$toasted.show("Sikeres fiók regisztráció!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          this.$toasted.show("Hibás adatok!", {
            position: "bottom-center",
            duration: 2500,
            fullWidth: true,
            fitToScreen: true,
            type: "error",
          });
        });
    },
    deleteUser() {
      //Check current user
      if (this.currentUser.id == this.userData.id) {
        //Toast success message
        this.$toasted.show("Jelenlegi felhasználó törlése nem lehetséges!", {
          position: "bottom-center",
          duration: 2500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
        });
        return;
      }

      axios
        .post(this.$store.getters.getApiUrl + "shop/users/delete", {
          token: this.$store.getters.getToken,
          userId: this.currentUser.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.users;
            this.showEditUserModal = false;

            //Toast success message
            this.$toasted.show("Sikeres fiók törlés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  computed: {
    ...mapGetters(["userData", "shopData"]),
  },
  mounted() {
    this.getShopUsers();
    console.log(this.userData);
    //Add bg class to body
    document.body.classList.add("myShopBg");
  },
  destroyed() {
    document.body.classList.remove("myShopBg");
  },
};
</script>
