<template>
  <div>
    <!--<Auth />-->

    <Header
        :back-route-id="parseInt($route.params.serviceId)"
        :title="service.title"
        back-route-name="SearchShopServicesEmployees"
        back-route-param="id"
        custom-class="bg-active text-light"
        :muted="muted"
    />

    <!--Send message button-->
    <!--<div-->
    <!--  v-if="service.user_id != user.id"-->
    <!--  class="fab-button animate top-right pb-5 mb-2"-->
    <!--&gt;-->
    <!--  <a-->
    <!--    href="javascript:void(0)"-->
    <!--    @click="messageModal = true"-->
    <!--    class="fab mainGreen"-->
    <!--    aria-expanded="false"-->
    <!--  >-->
    <!--    <ion-icon-->
    <!--      name="mail-outline"-->
    <!--      role="img"-->
    <!--      class="md hydrated"-->
    <!--      aria-label="add outline"-->
    <!--    ></ion-icon>-->
    <!--  </a>-->
    <!--</div>-->

    <!--Loader-->
    <div v-if="loader" class="row">
      <div class="col-12 align-content-center justify-content-center">
        <div class="mx-auto" style="height: 5rem; width: 5rem">
          <lottie-animation
              ref="anim"
              :animationData="require('@/assets/img/lottie/loader_ccacar.json')"
              :loop="true"
          />
        </div>
      </div>
    </div>

    <div v-if="!loader">

      <div class="section mt-2">
        <div class="profile-head">
          <div class="avatar">
            <div
                v-if="shop.profile_image"
                :style="{
              backgroundImage: 'url(' + shop.profile_image + ')',
            }"
                class="profileImage"
            ></div>
            <div
                v-else
                :style="{
              backgroundImage:
                'url(' + require('@/assets/img/profile.jpg') + ')',
            }"
                class="profileImage"
            ></div>
          </div>
          <div class="in">
            <h3 class="name">{{ shop.name }}</h3>
            <h5 class="subtext">
              <a :href="'mailto:' + shop.email">{{ shop.email }}</a>
            </h5>
            <h5 class="subtext mt-1">
              <a :href="'tel:' + shop.phone">{{ shop.phone }}</a>
            </h5>
            <h5 class="subtext mt-1">
              <a
                  :href="
                          'https://maps.google.com/?q=' +
                          shop.zip +
                          ' ' +
                          shop.city +
                          ', ' +
                          shop.address
                        "
              >{{ shop.zip }} {{ shop.city }}, {{ shop.address }}
              </a>
            </h5>
          </div>
          <!-- <div v-if="getLoggedIn && user.id !== service.user.id" class="row">
            <div class="col-2 offset-2">
              <div v-if="!in_contact" class="addContactContainer">
                <div @click="showContactAddModal = true" class="icon-inner">
                  <ion-icon name="person-add-outline"></ion-icon>
                </div>
              </div>
              <div
                v-else-if="in_contact && !contactAccepted"
                class="inProgressContactContainer"
              >
                <div class="icon-inner">
                  <ion-icon name="hourglass-outline"></ion-icon>
                </div>
              </div>
              <div v-else class="inContactContainer">
                <div class="icon-inner">
                  <ion-icon name="people-outline"></ion-icon>
                </div>
              </div>
            </div>
            <div class="col-2 offset-3">
              <div class="addContactContainer mainGreen">
                <div @click="messageModal = true" class="icon-inner">
                  <ion-icon
                    name="mail-outline"
                    role="img"
                    class="md hydrated"
                    aria-label="add outline"
                  ></ion-icon>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="section mt-2">
        <div class="profile-info p-1">
          <div class="bio my-1">
            <div class="row">
              <div class="col-12">
                Szolgáltatás leírása: <br/><br/>
                <strong>
                  <p>Az autó külső karosszériájának tisztása és ápolása, visszapillantó tükrök tisztítása, szélvédők- és
                    oldalüvegek tisztítása, dísztárcsa (alufelni) tisztítás, gumiabroncs külső felületének tisztítása,
                    ápolása.</p>
                </strong>
              </div>
            </div>
            <div class="row align-self-center">
              <div class="col-12">
                <div
                    v-if="getLoggedIn && user.id !== service.user.id"
                    class="row"
                >
                  <div class="col-6 mb-2">
                    <div v-if="!in_contact" class="addContactContainer mx-auto">
                      <div class="icon-inner" @click="showContactAddModal = true">
                        <ion-icon name="person-add-outline"></ion-icon>
                      </div>
                    </div>
                    <div
                        v-else-if="in_contact && !contactAccepted"
                        class="inProgressContactContainer mx-auto"
                    >
                      <div class="icon-inner">
                        <ion-icon name="hourglass-outline"></ion-icon>
                      </div>
                    </div>
                    <div v-else class="inContactContainer mx-auto">
                      <div class="icon-inner">
                        <ion-icon name="people-outline"></ion-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="addContactContainer mainGreen mx-auto">
                      <div class="icon-inner" @click="messageModal = true">
                        <ion-icon
                            aria-label="add outline"
                            class="md hydrated"
                            name="mail-outline"
                            role="img"
                        ></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section full mt-1">
        <div class="row text-center">
          <div class="col-6">
            <strong>{{ service.duration }}</strong> <small>perc</small>
          </div>
          <div class="col-6">
            <strong>{{ service.price }}</strong> <small>HUF</small>
          </div>
        </div>
      </div>

      <div class="section full text-center">
        <div class="row text-center p-1">
          <div class="col-12 p-1">
            <vc-calendar
                v-model="calendar"
                :attributes="attributes"
                :locale="localeSettings"
                is-expanded
                trim-weeks
                @dayclick="dayClicked"
                @update:to-page="updateCalendar"
            ></vc-calendar>
          </div>
        </div>
      </div>

      <!--<div class="section full">-->
      <!--  <div class="wide-block pt-2 pb-2">-->
      <!--    <button-->
      <!--      @click="dayChooserModal = true"-->
      <!--      type="button"-->
      <!--      class="btn btn-primary btn-lg btn-block"-->
      <!--    >-->
      <!--      {{ currentDay }}-->
      <!--    </button>-->
      <!--  </div>-->
      <!--</div>-->

      <!--Appointments list-->
      <div class="w-100 text-center pb-2 calendarCurrentDate">
        {{ currentDay }}
      </div>

      <div class="appointmentsListContainer">
        <!-- Non private calendar case -->
        <ul
            v-if="
          appointments &&
          appointments.length > 1 &&
          employee.private_calendar === 0
        "
            class="listview link-listview appointmentsList"
        >
          <li v-for="(appointment, index) in appointments" :key="index">
            <a
                href="javascript:void(0)"
                @click="chooseAnAppointment(appointment)"
            >
              {{ appointment.time_start }} - {{ appointment.time_stop }}
              <span
                  v-if="appointment.is_available === 1"
                  class="badge badge-success"
              >ELÉRHETŐ</span
              >
              <span
                  v-else-if="appointment.is_available === 2"
                  class="badge badge-warning"
              >JÓVÁHAGYÁSRA VÁR</span
              >
              <span v-else class="badge badge-danger">FOGLALT</span>
            </a>
          </li>
        </ul>

        <!-- Private calendar case -->
        <ul
            v-else-if="
          appointments &&
          appointments.length > 0 &&
          employee.private_calendar === 1 &&
          in_contact &&
          contactAccepted
        "
            class="listview link-listview appointmentsList"
        >
          <li v-for="(appointment, index) in appointments" :key="index">
            <a
                href="javascript:void(0)"
                @click="chooseAnAppointment(appointment)"
            >
              <div>
                {{ appointment.time_start }} - {{ appointment.time_stop }}
              </div>
              <span
                  v-if="appointment.is_available === 1"
                  class="badge badge-success"
              >ELÉRHETŐ</span
              >
              <span
                  v-else-if="appointment.is_available === 2"
                  class="badge badge-warning"
              >JÓVÁHAGYÁSRA VÁR</span
              >
              <span v-else class="badge badge-danger">FOGLALT</span>
            </a>
          </li>
        </ul>

        <!--Private appointmets case-->
        <div
            v-else-if="employee.private_calendar === 1 && contactAccepted === false"
            class="section full"
        >
          <ErrorPanel
              class-label="alert alert-primary mainGreen"
              message="Csak kapcsolatba lépés után "
              message2="láthatóak a szabad időpontok!"
              title="Privát időpontok!"
          />
        </div>

        <div v-else class="section full">
          <ErrorPanel
              class-label="alert alert-primary mainGreen"
              message="Nincsenek elérhető időpontok!"
              message2="Kérlek nézz meg egy másik napot!"
              title="Hoppá!"
          />
        </div>
      </div>

    </div>
    <!--Modal to booking an appointment-->
    <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
        name="custom-classes-transition"
    >
      <div
          v-if="bookModal"
          id="DialogBlockButton2"
          aria-modal="true"
          class="modal fade dialogbox show"
          data-backdrop="static"
          role="dialog"
          style="display: block"
          tabindex="-1"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Időpont lefoglalása</h5>
            </div>

            <div v-if="bookedAppointment.is_available === 1" class="modal-body">
              Biztosan lefoglalod ezt <br/>
              <strong class="text-white">
                {{ bookedAppointment.time_start }} -
                {{ bookedAppointment.time_stop }}
              </strong>
              <br/>
              az időpontot?

              <div class="row mt-3">
                <div class="col-12">Értesítés időzítése</div>
                <div class="col-5 offset-1">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <input
                          id="reminderTime"
                          v-model="reminder.time"
                          :class="{ 'is-invalid': errors.reminderTime }"
                          class="form-control text-center"
                          name="reminderTime"
                          type="text"
                      />
                      <div v-if="errors.reminderTime" class="invalid-feedback">
                        {{ errors.reminderTime[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 text-center">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <select
                          id="reminderUnit"
                          v-model="reminder.unit"
                          :class="{ 'is-invalid': errors.reminderUnit }"
                          class="form-control"
                          name="reminderUnit"
                          type="text"
                      >
                        <option value="perc">perc</option>
                        <option value="óra">óra</option>
                        <option value="nap">nap</option>
                      </select>
                      <div v-if="errors.reminderUnit" class="invalid-feedback">
                        {{ errors.reminderUnit[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="modal-body">Ez az időpont már nem elérhető!</div>

            <div class="modal-footer">
              <div class="btn-list">
                <a
                    v-if="
                    bookedAppointment.is_available === 1 &&
                    bookedAppointment.user_id != user.id
                  "
                    class="btn btn-text-success btn-block"
                    href="javascript:void(0)"
                    @click="bookingAnAppointment"
                >LEFOGLALOM</a
                >
                <a
                    v-else
                    class="btn btn-text-info btn-block"
                    href="javascript:void(0)"
                >SAJÁT SZOLGÁLTATÁS</a
                >
                <a
                    class="btn btn-text-secondary btn-block"
                    data-dismiss="modal"
                    href="javascript:void(0)"
                    @click="bookModal = false"
                >MÉGSE</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <!--Please log in modal-->
    <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
        name="custom-classes-transition"
    >
      <div
          v-if="pleaseLogInModal"
          id="DialogBlockButton3"
          aria-modal="true"
          class="modal fade dialogbox show"
          data-backdrop="static"
          role="dialog"
          style="display: block"
          tabindex="-1"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Időpont lefoglalása</h5>
            </div>

            <div class="modal-body">
              Időpont foglaláshoz kérlek jelentkezz be!
            </div>

            <div class="modal-footer">
              <div class="btn-list">
                <a
                    class="btn btn-text-success btn-block"
                    href="javascript:void(0)"
                    @click="$router.push({ name: 'Login' })"
                >BEJELENTKEZÉS</a
                >
                <a
                    class="btn btn-text-secondary btn-block"
                    data-dismiss="modal"
                    href="javascript:void(0)"
                    @click="pleaseLogInModal = false"
                >MÉGSE</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <!--Send message to ServiceProvider Modal-->
    <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
        name="custom-classes-transition"
    >
      <div
          v-if="messageModal"
          id="DialogForm"
          aria-modal="true"
          class="modal fade dialogbox show"
          data-backdrop="static"
          role="dialog"
          style="display: block"
          tabindex="-1"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Üzenet küldés</h5>
            </div>
            <div class="modal-body text-left mb-2">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="message">Üzenet</label>
                  <input
                      id="message"
                      v-model="message"
                      class="form-control"
                      placeholder="Mit szeretnél üzenni?"
                      type="text"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="btn-inline">
                <button
                    class="btn btn-text-secondary"
                    data-dismiss="modal"
                    type="button"
                    @click="messageModal = false"
                >
                  MÉGSE
                </button>
                <button
                    class="btn btn-text-primary"
                    data-dismiss="modal"
                    type="button"
                    @click="sendMessage"
                >
                  KÜLDÉS
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <!--Add contact modal-->
    <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
        name="custom-classes-transition"
    >
      <div
          v-if="showContactAddModal"
          id="showContactAddModal"
          aria-modal="true"
          class="modal fade dialogbox show"
          data-backdrop="static"
          role="dialog"
          style="display: block"
          tabindex="-1"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Új kapcsolat hozzáadása</h5>
            </div>
            <div class="modal-body text-left mb-2">
              Biztosan hozzáadod a kapcsolataidhoz?
            </div>
            <div class="modal-footer">
              <div class="btn-inline">
                <button
                    class="btn btn-text-secondary"
                    data-dismiss="modal"
                    type="button"
                    @click="showContactAddModal = false"
                >
                  MÉGSE
                </button>
                <button
                    class="btn btn-text-primary"
                    type="button"
                    @click="addNewContact"
                >
                  MENTÉS
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <Nav/>
    <NavFooter/>
  </div>
</template>

<script>
import Header from "../partials/Header";
// import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import {mapGetters} from "vuex";

import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
import LottieAnimation from "lottie-web-vue";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Service",
  components: {
    LottieAnimation,
    ErrorPanel,
    // Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    service: {
      title: 'betöltés...'
    },
    muted: '',
    appointments: {},
    reminder: {
      time: 5,
      unit: "perc",
    },
    calendar: new Date(),
    attributes: {
      key: "today",
      highlight: true,
      dates: new Date(),
    },
    localeSettings: {
      id: "hu",
      firstDayOfWeek: 2,
      masks: {
        weekdays: "WW",
      },
    },
    serviceProvider: {},
    currentDay: "",
    dayChooserModal: false,
    days: {},
    bookModal: false,
    messageModal: false,
    pleaseLogInModal: false,
    bookedAppointment: {},
    message: "",
    showContactAddModal: false,
    in_contact: false,
    contactAccepted: false,
    employee: {},
    shop: {},
    loader: true,
  }),
  methods: {
    getService() {
      axios
          .post(this.$store.getters.getApiUrl + "service/show", {
            token: this.$store.getters.getToken,
            service_id: this.$route.params.serviceId,
            employee_id: this.$route.params.employeeId,
            user_id: this.$store.getters.getUserData.id,
          })
          .then((response) => {
            if (response.status === 200) {
              this.service = response.data.service;
              this.employee = response.data.employee;
              this.shop = response.data.shop;
              this.serviceProvider = response.data.employee;
              this.currentDay = response.data.currentDay;
              this.days = response.data.days;
              this.appointments = response.data.appointments;
              // this.attributes = response.data.attributes;
              this.in_contact = response.data.in_contact;
              this.contactAccepted = response.data.contactAccepted;
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
    },
    chooseAnAppointment(appointment) {
      if (this.getLoggedIn) {
        this.bookedAppointment = appointment;
        this.bookModal = true;
      } else {
        this.pleaseLogInModal = true;
      }
    },
    bookingAnAppointment() {
      axios
          .post(this.$store.getters.getApiUrl + "service/book/appointment", {
            token: this.$store.getters.getToken,
            id: this.bookedAppointment.service_id,
            time_start: this.bookedAppointment.time_start,
            time_stop: this.bookedAppointment.time_stop,
            currentDay: this.bookedAppointment.date,
            reminderTime: this.reminder.time,
            reminderUnit: this.reminder.unit,
          })
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              this.appointments = response.data.appointments;
              this.bookModal = false;

              if (response.data.dayLimitReached) {
                //Toaster from day limit reached warning
                this.$toasted.show("Erre a napra több időpont nem foglalható!", {
                  position: "bottom-center",
                  duration: 2500,
                  fullWidth: true,
                  fitToScreen: false,
                  type: "error",
                  // icon: "checkmark-outline"
                });
              } else {
                //Toaster from successfully state modification
                this.$toasted.show("Sikeres jelentkezés!", {
                  position: "bottom-center",
                  duration: 2500,
                  fullWidth: true,
                  fitToScreen: false,
                  type: "success",
                  // icon: "checkmark-outline"
                });
              }
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
    },
    sendMessage() {
      if (!this.message) {
        this.$toasted.show("Üzenet megadása kötelező!", {
          position: "bottom-center",
          duration: 2500,
          fullWidth: true,
          fitToScreen: false,
          type: "error",
          // icon: "checkmark-outline"
        });
      } else {
        axios
            .post(
                this.$store.getters.getApiUrl +
                "conversation/message/sendFromSearchService",
                {
                  token: this.$store.getters.getToken,
                  id: this.$route.params.employeeId,
                  message: this.message,
                }
            )
            .then((response) => {
              if (response.status === 200) {
                //Close modal & reset message value
                this.messageModal = false;
                this.message = "";

                //Show a toaster with success
                this.$toasted.show("Sikeres üzenetküldés!", {
                  position: "bottom-center",
                  duration: 2500,
                  fullWidth: true,
                  fitToScreen: false,
                  type: "success",
                  // icon: "checkmark-outline"
                });
              }
            })
            .catch((error) => {
              this.errors = error.response.data.errors;
            });
      }
    },
    dayClicked(day) {
      //Change currentdate
      this.currentDay = day.id.replaceAll("-", ".");

      //Send axios request
      axios
          .post(this.$store.getters.getApiUrl + "service/show/appointments", {
            token: this.$store.getters.getToken,
            id: this.$route.params.serviceId,
            day: day.id,
          })
          .then((response) => {
            if (response.status === 200) {
              this.attributes = response.data.attributes;
              this.appointments = response.data.appointments;
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
    },
    addNewContact() {
      axios
          .post(this.$store.getters.getApiUrl + "contact/store/registered", {
            token: this.$store.getters.getToken,
            contact_user_id: this.service.user.id,
          })
          .then((response) => {
            if (response.status === 200) {
              this.in_contact = response.data.in_contact;
              this.contactAccepted = response.data.contactAccepted;
              this.showContactAddModal = false;
              this.getService();

              //Show a toaster with success
              this.$toasted.show("Sikeres hozzáadás!", {
                position: "bottom-center",
                duration: 2500,
                fullWidth: true,
                fitToScreen: false,
                type: "success",
                // icon: "checkmark-outline"
              });
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
    },
    updateCalendar(calendar) {
      axios
          .post(this.$store.getters.getApiUrl + "calendar/getByMonthService", {
            month: calendar.month,
            year: calendar.year,
            day: this.currentDate,
            service_id: this.$route.params.serviceId,
            employee_id: this.$route.params.employeeId,
          })
          .then((response) => {
            //Init attributes to null
            //   this.attributes = null;
            //   this.attributes = {
            //     key: "today",
            //     highlight: true,
            //     dates: new Date(),
            //   };

            //When the response is 200, we set the calendar attributes
            if (response.status === 200) {
              this.attributes = response.data.attributes;
            }
          })
          .catch((error) => {
            if (error.response.length > 0) {
              this.errors = error.response.data.errors;
            }
          });
    },
  },
  computed: {
    ...mapGetters(["getCurrentCategoryId", "getLoggedIn", "user"]),
  },
  mounted() {
    //Get service
    this.getService();

    // Init loader
    this.loader = true;
    let v = this;
    setTimeout(function () {
      v.$data.loader = false;
      v.$data.muted = 'szolgáltatás';
    }, 2000);
  },
};
</script>
