<template>
  <div class="login-form">
    <div class="section">
      <img
        :src="require('@/assets/img/logo/cca-full-white-logo-md.png')"
        alt="fixeet_logo"
        class="loginLogo"
        @click="$router.push({ name: 'Dashboard' })"
      />
      <!--<h2 class="mt-5 text-white">Regisztráció</h2>-->
      <!--<h3 class="text-white">Készítsük el fiókod</h3>-->
    </div>
    <div class="section mt-5">
      <!--<h1>Regisztráció</h1>-->
      <!--<h4>Készítsük el fiókodat</h4>-->
    </div>
    <div class="section mt-1 mb-5">
      <div class="form-button-group mb-5">
        <div>
          <button
            @click="$router.push({ name: 'CustomerStep1' })"
            class="btn btn-blue btn-block btn-lg"
          >
            Regisztráció ügyfélként
          </button>
<!--          <button-->
<!--            @click="$router.push({ name: 'ServiceProviderStep1' })"-->
<!--            class="btn btn-magenta btn-block btn-lg"-->
<!--          >-->
<!--            Regisztráció szolgáltatóként-->
<!--          </button>-->
          <button
            @click="$router.push({ name: 'Home' })"
            class="btn btn-green btn-block btn-lg"
          >
            Bejelentkezés
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Registration",
  components: {},
  mounted() {
    //Add bg class to body
    document.body.classList.add("registrationBg");
  },
  destroyed() {
    document.body.classList.remove("registrationBg");
  },
};
</script>

<style>
</style>
