<template>
  <div
      class="bgDashboard mainDashboard disableYPadding"
      style="position: relative"
  >
    <!--<Auth />-->
    <div class="row px-4">
      <div class="col-1 text-left">
        <div class="icon-custom icon-menu-white" @click="updateMenuState"></div>
        <span
            v-if="
            (notificationsCounter > 0 || contactCounter > 0) &&
            notificationsCounter + contactCounter >= 10
          "
            class="badge badge-pill badge-active badge-dashboard"
        >9+
        </span>
        <span
            v-else-if="
            notificationsCounter > 0 ||
            (contactCounter > 0 && notificationsCounter + contactCounter < 10)
          "
            class="badge badge-pill badge-active badge-dashboard"
        >{{ notificationsCounter + contactCounter }}
        </span>
      </div>
    </div>
    <!-- <Header title="Főoldal" :transparent="true" /> -->

    <div class="header-title dashboardTitle p-3">
      <h3 v-if="loggedIn" class="text-light">Üdv újra a CleanCar App-ban!</h3>
      <h3 v-else class="text-light">Üdvözöllek a CleanCar App-ban!</h3>
      <div class="subtitle dashboardSubtitle">
        Találd meg a kedvenc autómosód és foglalj időpontot akár
        <strong>30 mp</strong> alatt!
      </div>
    </div>

    <div
        v-if="loggedIn && user.email_verified == 0"
        class="section full my-3 text-center"
    >
      <div class="wide-block">
        <div class="alert alert-danger" role="alert">
          <h4 class="alert-title">😮 Fiókod még nincs aktiválva 🤔</h4>
        </div>
      </div>
    </div>

    <Categories/>
    <Nav/>

    <div
        class="section full mb-3 text-center"
    >
      <h3 class="text-light">🧭 Térkép 🧭</h3>
      <div class="wide-block">
        <div class="card">
          <GmapMap
              :center="{lat:46.912868, lng:19.050293}"
              :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false
        }"
              :zoom="7"
              map-type-id="terrain"
              style="width: 100%; height: 300px; margin-bottom: 2rem;"
          >
            <!--<GmapMarker-->
            <!--    v-for="(m, index) in markers"-->
            <!--    :key="index"-->
            <!--    :clickable="false"-->
            <!--    :draggable="false"-->
            <!--    :position="m.position"-->
            <!--    @click="center=m.position"-->
            <!--/>-->
            <!--<img :src="require('@/assets/img/gmap-pin.png')" alt="marker">-->
            <GmapCustomMarker
                v-for="(m, index) in markers"
                :key="index"
                :marker="m.position"
                >
              <img :src="require('@/assets/img/gmap-pin-cca-xs3.png')" />
            </GmapCustomMarker>
          </GmapMap>
        </div>
      </div>
    </div>

    <NavFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import Categories from "../partials/Categories";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import GmapCustomMarker from 'vue2-gmap-custom-marker';

export default {
  name: "Dashboard",
  components: {
    // Cards,
    Categories,
    Nav,
    NavFooter,
    GmapCustomMarker
  },
  data: () => ({
    user: {},
    showTutorialModal: false,
    markers: [
      // {position: {lat: 47.576050, lng: 18.375460}, url: "../assets/img/gmap-pin.png"},
      // {position: {lat: 47.577160, lng: 18.393340}, url: "../assets/img/gmap-pin.png"},
      // {position: {lat: 47.497913, lng: 19.040236}, url: "../assets/img/gmap-pin.png"},
      // {position: {lat: 46.909061, lng: 18.074623}, url: "../assets/img/gmap-pin.png"},
      // {position: {lat: 47.124770, lng: 20.206540}, url: "../assets/img/gmap-pin.png"},
    ]
  }),
  methods: {
    ...mapActions(["updateMenuState", "updateDarkMode"]),
      updateDarkModeFunction() {
      this.$store.commit("updateDarkMode");

      const el = document.body;

      if (this.user.darkMode) {
        el.classList.add("dark-mode-active");
      } else {
        el.classList.remove("dark-mode-active");
      }
    },
    openMenu() {
      this.$store.commit("updateMenu", {
        show: this.menuShow,
      });
    },
    getMapMarkers() {
      axios
          .post(this.$store.getters.getApiUrl + "map/markers")
          .then((response) => {
            if (response.status === 200) {
              this.markers = response.data.markers;
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
    }
  },
  computed: {
    ...mapGetters([
      "loggedIn",
      "menuShow",
      "notificationsCounter",
      "contactCounter"
    ]),
  },
  mounted() {
    this.user = this.$store.getters.getUserData;
    this.getMapMarkers();
  },
  beforeUnmount() {
  }
};
</script>

<style>
</style>
