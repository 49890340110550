<template>
  <div>
    <Auth />

    <Header
      title="Időpontjaim"
      muted=""
      back-route-name="Dashboard"
      back-route-param="id"
      custom-class="bg-active text-light"
    />

    <!--Options button-->
    <div class="fab-button animate bottom-right pb-5 mb-2">
      <a
        href="javascript:void(0)"
        class="fab"
        @click="showButtonOptions = !showButtonOptions"
      >
        <ion-icon
          v-if="showButtonOptions"
          name="close-outline"
          role="img"
          class="md hydrated"
          aria-label="add outline"
        ></ion-icon>
        <ion-icon
          v-else
          name="ellipsis-horizontal-outline"
          role="img"
          class="md hydrated"
          aria-label="add outline"
        ></ion-icon>
      </a>
      <div
        v-if="showButtonOptions"
        class="dropdown-menu show"
        style="
          position: absolute;
          transform: translate3d(0px, -175px, 0px);
          top: 0px;
          left: 0px;
          will-change: transform;
        "
      >
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          @click="
            $router.push({ name: 'ServiceProviderCalendarNewAppointment' })
          "
        >
          <ion-icon
            name="add-outline"
            role="img"
            class="md hydrated"
            aria-label="add outline"
          ></ion-icon>
          <p>Új időpont</p>
        </a>
        <a
          href="javascript:void(0)"
          @click="openWorkdayModal"
          class="dropdown-item"
        >
          <ion-icon
            name="calendar-outline"
            role="img"
            class="md hydrated"
            aria-label="calendar outline"
          ></ion-icon>
          <p>Munkaidő módosítás</p>
        </a>
        <a
          href="javascript:void(0)"
          @click="$router.push({ name: 'MassWorkday' })"
          class="dropdown-item"
        >
          <ion-icon
            name="duplicate-outline"
            role="img"
            class="md hydrated"
            aria-label="duplicate outline"
          ></ion-icon>
          <p>Csoportos munkaidő megadás</p>
        </a>
      </div>
    </div>

    <!--Work days notification-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated SlideDownIn"
      leave-active-class="animated SlideUpOut"
    >
      <div
        v-if="workdayNotifier"
        id="notification-5"
        class="notification-box show"
      >
        <div class="notification-dialog android-style">
          <div class="notification-content">
            <div class="in">
              <h3 class="subtitle">Hiányzó munkaidő</h3>
              <div class="text">
                Erre a napra nem adtál meg munkaidőt! <br />
                Amenniyben nem állítasz be munkaidőt, nem tudnak időpontot
                foglalni nálad!
              </div>
            </div>
            <div class="icon-box text-black-100">
              <div
                class="icon-custom icon-close-circle"
                style="width: 40px; height: 40px"
              ></div>
            </div>
          </div>
          <div class="notification-footer text-black-100">
            <a
              href="javascript:void(0)"
              class="notification-button text-black-100"
              @click="
                workdayNotifier = false;
                newWorkdayModal = true;
              "
            >
              <ion-icon
                name="add-outline"
                role="img"
                class="md hydrated"
                aria-label="arrow down circle"
              ></ion-icon>
              Új megadás
            </a>
            <a
              href="javascript:void(0)"
              class="notification-button text-black-100"
              @click="workdayNotifier = false"
            >
              <ion-icon
                name="close"
                role="img"
                class="md hydrated"
                aria-label="cloud"
              ></ion-icon>
              Bezár
            </a>
          </div>
        </div>
      </div>
    </transition>

    <div class="section full mt-2 text-center">
      <div class="row text-center p-1">
        <div class="col-12 p-1">
          <vc-calendar
            trim-weeks
            is-expanded
            :locale="localeSettings"
            :attributes="attributes"
            v-model="calendar"
            @update:to-page="updateCalendar"
            @dayclick="dayClicked"
          ></vc-calendar>
        </div>
      </div>
    </div>

    <div class="section full">
      <!--Current date-->
      <div class="w-100 text-center calendarCurrentDate">
        {{ currentDate }}
      </div>

      <!-- Appointments list -->
      <ul
        v-if="appointments && appointments.length > 0"
        class="listview link-listview"
      >
        <li v-for="(appointment, index) in appointments" :key="index">
          <a
            href="javascript:void(0)"
            @click="showAppointmentDetails(appointment)"
          >
            <div>
              <small>
                <strong v-if="appointment.client && appointment.client.name">
                  {{ appointment.client.name }}
                </strong>
                <strong
                  v-else-if="appointment.contact && appointment.contact.name"
                  >{{ appointment.contact.name }}</strong
                >
                <strong v-else>Törölt kapcsolat</strong>
              </small>
              <br />
              <small>{{ appointment.service.title }}</small> <br />
              {{ appointment.time_start }}
            </div>
            <span
              v-if="appointment.is_available === 1"
              class="badge badge-success"
              >ELÉRHETŐ</span
            >
            <span
              v-else-if="appointment.is_available === 2"
              class="badge badge-warning"
              >JÓVÁHAGYÁSRA VÁR</span
            >
            <span
              v-else-if="appointment.is_available === 3"
              class="badge badge-success"
              >MEGERŐSÍTVE</span
            >
            <span
              v-else-if="appointment.is_available === 4"
              class="badge badge-warning"
              >TÖRLÉS JÓVÁHAGYÁSRA VÁR</span
            >
          </a>
        </li>
      </ul>

      <div v-else class="section full">
        <div class="wide-block-transparent pt-2 pb-2">
          <div class="alert alert-primary mainGreen" role="alert">
            <h4 class="alert-title">Hoppá!</h4>
            Nincsenek lefoglalt időpontok! <br />
            Kérlek nézz meg egy másik napot!
          </div>
        </div>
      </div>
    </div>

    <!--Modal to show an appointment-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="detailsModal && user.type !== 0"
        class="modal fade dialogbox show"
        id="DialogBlockButton2"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Időpont részletei</h5>
            </div>

            <div class="modal-body">
              <div v-if="detailedAppointment.client" class="mb-2">
                <h5>Kliens adatok:</h5>
                <strong> {{ detailedAppointment.client.name }} </strong> <br />
                <strong> {{ detailedAppointment.client.phone }} </strong> <br />
                <strong> {{ detailedAppointment.client.email }} </strong> <br />
              </div>

              <div v-else-if="detailedAppointment.contact" class="mb-2">
                <h5>Kliens adatok:</h5>
                <strong> {{ detailedAppointment.contact.name }} </strong> <br />
                <strong> {{ detailedAppointment.contact.phone }} </strong>
                <br />
                <strong> {{ detailedAppointment.contact.email }} </strong>
                <br />
              </div>

              <div v-else class="mb-2">
                <h5>Kliens adatok:</h5>
                <strong> Törölt felhasználó </strong> <br />
              </div>

              <div class="">
                <h5>Időpont:</h5>
                <small>Kezd:</small>
                <strong> {{ detailedAppointment.time_start }} </strong> <br />
                <small>Befejez:</small>
                <strong> {{ detailedAppointment.time_stop }} </strong>
              </div>
            </div>

            <div class="modal-footer">
              <div class="btn-list">
                <!--<a-->
                <!--  v-if="-->
                <!--    detailedAppointment.client !== null &&-->
                <!--      !detailedAppointment.client.in_contact-->
                <!--  "-->
                <!--  href="javascript:void(0)"-->
                <!--  @click="addNewContact(detailedAppointment.client)"-->
                <!--  class="btn btn-text-success btn-block"-->
                <!--  >KAPCSOLAT MENTÉSE</a-->
                <!--&gt;-->
                <a
                  href="javascript:void(0)"
                  @click="
                    $router.push({
                      name: 'ServiceProviderAppointmentEdit',
                      params: { id: detailedAppointment.id },
                    })
                  "
                  class="btn btn-text-success btn-block"
                  >SZERKESZTÉS</a
                >
                <a
                  v-if="detailedAppointment.is_available > 1"
                  href="javascript:void(0)"
                  @click="
                    deleteModal = true;
                    detailsModal = false;
                  "
                  class="btn btn-text-danger btn-block"
                  >TÖRLÉS</a
                >
                <a
                  href="javascript:void(0)"
                  @click="detailsModal = false"
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                  >BEZÁR</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <!--Remove appointment modal-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="deleteModal && user.type !== 0"
        class="modal fade dialogbox show"
        id="DialogBlockButton4"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <!--<h5 class="modal-title">Időpont törlése</h5>-->
            </div>

            <div class="modal-body">
              <h3 class="text-danger mt-n2 mb-2">
                Biztosan törölni szeretnéd az alábbi időpontot?
              </h3>

              <div v-if="detailedAppointment.client" class="mb-2">
                <h5>Kliens adatok:</h5>
                <strong> {{ detailedAppointment.client.name }} </strong> <br />
                <strong> {{ detailedAppointment.client.phone }} </strong> <br />
                <strong> {{ detailedAppointment.client.email }} </strong> <br />
              </div>

              <div class="">
                <h5>Időpont:</h5>
                <small>Kezd:</small>
                <strong> {{ detailedAppointment.time_start }} </strong> <br />
                <small>Befejez:</small>
                <strong> {{ detailedAppointment.time_stop }} </strong>
              </div>
            </div>

            <div class="modal-footer">
              <div class="btn-list">
                <a
                  v-if="detailedAppointment.is_available > 1"
                  href="javascript:void(0)"
                  @click="deleteAppointment"
                  class="btn btn-text-danger btn-block"
                  >TÖRLÉS</a
                >
                <a
                  href="javascript:void(0)"
                  @click="
                    deleteModal = false;
                    detailsModal = true;
                  "
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                  >BEZÁR</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <!--Modal to modify or create a workday-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="newWorkdayModal && user.type != 0"
        class="modal fade dialogbox show"
        id="DialogBlockButton3"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Munkanap</h5>
            </div>

            <div class="modal-body">
              <div class="form-group boxed">
                <div class="input-wrapper not-empty">
                  <label class="label ml-1">Kezdés</label>
                  <!-- <datetime
                    type="time"
                    v-model="workDay.time_start"
                    input-class="form-control"
                    auto
                    value-zone="Europe/Budapest"
                    zone="Europe/Budapest"
                  ></datetime> -->

                  <input
                    type="text"
                    v-model="workDay.time_start_show"
                    class="form-control"
                    placeholder="08:00"
                  />

                  <i class="clear-input pb-4 mb-4">
                    <ion-icon
                      name="alarm-outline"
                      role="img"
                      class="md hydrated"
                      aria-label="add outline"
                      @click="openDateTime('start')"
                    ></ion-icon>
                  </i>

                  <datetime
                    type="time"
                    ref="dateTimeStart"
                    v-model="workDay.time_start"
                    input-class="form-control"
                    value-zone="Europe/Budapest"
                    zone="Europe/Budapest"
                    :phrases="{ ok: 'Mentés', cancel: 'Mégse' }"
                    format="HH:mm"
                    title="Munkanap kezdés"
                    auto
                  >
                  </datetime>
                  <!-- <div class="invalid-feedback" v-if="errors.time_start">
                    {{ errors.time_start[0] }}
                  </div> -->
                </div>
              </div>

              <div class="form-group boxed">
                <div class="input-wrapper not-empty">
                  <label class="label ml-1">Befejezés</label>

                  <input
                    type="text"
                    v-model="workDay.time_stop_show"
                    class="form-control"
                    placeholder="17:00"
                  />

                  <i class="clear-input pb-4 mb-4">
                    <ion-icon
                      name="alarm-outline"
                      role="img"
                      class="md hydrated"
                      aria-label="add outline"
                      @click="openDateTime('stop')"
                    ></ion-icon>
                  </i>

                  <datetime
                    type="time"
                    ref="dateTimeStop"
                    v-model="workDay.time_stop"
                    input-class="form-control"
                    value-zone="Europe/Budapest"
                    zone="Europe/Budapest"
                    :phrases="{ ok: 'Mentés', cancel: 'Mégse' }"
                    format="HH:mm"
                    title="Munkanap befejezés"
                    auto
                  >
                  </datetime>

                  <!-- <div class="invalid-feedback" v-if="errors.time_stop">
                    {{ errors.time_stop[0] }}
                  </div> -->
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <div class="btn-inline">
                <button
                  type="button"
                  class="btn btn-text-success"
                  @click="checkWorkday"
                >
                  MENTÉS
                </button>
                <button
                  type="button"
                  class="btn btn-text-secondary"
                  @click="newWorkdayModal = false"
                >
                  BEZÁR
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <!-- Modal to notify user, there is an appointment out the workhours -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="workdayModalNotification && user.type != 0"
        class="modal fade dialogbox show"
        id="DialogBlockButton3"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Figyelem!</h5>
            </div>

            <div class="modal-body">
              Van olyan időpont, ami az új munkaidőn kívül esik! <br />
              Lemondja ezeket az időpontokat?
            </div>

            <div class="modal-footer">
              <div class="btn-inline">
                <button
                  type="button"
                  class="btn btn-text-danger"
                  @click="updateOrCreateWorkday"
                >
                  LEMOND
                </button>
                <button
                  type="button"
                  class="btn btn-text-secondary"
                  @click="
                    workdayModalNotification = false;
                    newWorkdayModal = true;
                  "
                >
                  MÉGSE
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

// import moment from "moment-timezone";
import axios from "axios";
import { mapGetters } from "vuex";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ServiceProviderCalendar",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    appointments: {},
    detailsModal: false,
    detailedAppointment: {},
    calendar: new Date(),
    attributes: {
      key: "today",
      highlight: true,
      dates: new Date(),
    },
    localeSettings: {
      id: "hu",
      firstDayOfWeek: 2,
      masks: {
        weekdays: "WW",
      },
    },
    workdayNotifier: false,
    newWorkdayModal: false,
    workDay: {
      id: null,
      time_start: "",
      time_stop: "",
      time_start_show: "",
      time_stop_show: "",
    },
    currentDate: "",
    deleteModal: false,
    showButtonOptions: false,
    timeSync: true, //def false
    workdayModalNotification: false,
  }),
  methods: {
    async getAppointmentsForCalendar() {
      await axios
        .post(this.$store.getters.getApiUrl + "calendar", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            // console.log("SP calendar data: ", response.data);
            this.attributes = response.data.attributes;
            this.appointments = response.data.appointments;
            this.workdayNotifier = response.data.workdayNotifier;
            // this.workDay = response.data.workday;
            this.currentDate = response.data.currentDate;

            //Update choosed date to add new appointment
            this.$store.commit("updateCalendarChoosedDate", {
              calendarChoosedDate: response.data.currentDate,
            });

            //Set workday data
            if (response.data.workDay) {
              this.workDay.id = response.data.workDay.id;
              this.workDay = response.data.workDay;
              //   this.workDay.time_start = response.data.workDay.time_start;
              //   this.workDay.time_stop = response.data.workDay.time_stop;
              //   this.workDay.time_start_show =
              //     response.data.workDay.time_start_show;
              //   this.workDay.time_stop_show =
              //     response.data.workDay.time_stop_show;
            } else {
              this.workDay.id = 0;
            }
          }

          this.timeSync = true;
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    dayClicked(day) {
      axios
        .post(this.$store.getters.getApiUrl + "calendar/getByDay", {
          token: this.$store.getters.getToken,
          day: day.id,
        })
        .then((response) => {
          this.attributes = {
            key: "today",
            highlight: true,
            dates: new Date(),
          };

          if (response.status === 200) {
            this.attributes = response.data.attributes;
            this.appointments = response.data.appointments;
            this.workdayNotifier = response.data.workdayNotifier;
            // this.workDay = response.data.workday;
            this.currentDate = response.data.currentDate;

            //Update choosed date to add new appointment
            this.$store.commit("updateCalendarChoosedDate", {
              calendarChoosedDate: this.currentDate,
            });

            //Set workday data
            if (response.data.workDay) {
              this.workDay.id = response.data.workDay.id;
              this.workDay = response.data.workDay;
            } else {
              this.workDay.id = 0;
            }
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    async refreshDay() {
      await axios
        .post(this.$store.getters.getApiUrl + "calendar/getByDay", {
          token: this.$store.getters.getToken,
          day: this.currentDate,
        })
        .then((response) => {
          if (response.status === 200) {
            this.attributes = response.data.attributes;
            this.appointments = response.data.appointments;
            this.workdayNotifier = response.data.workdayNotifier;
            // this.workDay = response.data.workday;
            this.currentDate = response.data.currentDate;

            //Update choosed date to add new appointment
            this.$store.commit("updateCalendarChoosedDate", {
              updateCalendarChoosedDate: response.data.currentDate,
            });

            //Set workday data
            //Init workday id for update or for create
            if (response.data.workDay && response.data.workDay.id) {
              this.workDay.id = response.data.workDay.id;
              this.workDay.time_start = response.data.workDay.time_start;
              this.workDay.time_stop = response.data.workDay.time_stop;
              this.workDay.time_start_show =
                response.data.workDay.time_start_show;
              this.workDay.time_stop_show =
                response.data.workDay.time_stop_show;
            } else {
              this.workDay.id = 0;
            }
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    showAppointmentDetails(appointment) {
      this.detailedAppointment = appointment;
      this.detailsModal = true;
    },
    confirmateAppointment() {
      axios
        .post(
          this.$store.getters.getApiUrl + "calendar/appointment/confirmation",
          {
            token: this.$store.getters.getToken,
            id: this.detailedAppointment.id,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.appointments = response.data.appointments;
            this.detailsModal = false;
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    deleteAppointment() {
      axios
        .post(this.$store.getters.getApiUrl + "calendar/appointment/delete", {
          token: this.$store.getters.getToken,
          id: this.detailedAppointment.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.appointments = response.data.appointments;
            this.deleteModal = false;
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    async checkWorkday() {
      await axios
        .post(this.$store.getters.getApiUrl + "workday/check", {
          token: this.$store.getters.getToken,
          id: this.workDay.id,
          currentDate: this.currentDate,
          timeStart: this.workDay.time_start,
          timeStop: this.workDay.time_stop,
        })
        .then((response) => {
          if (response.status === 200) {
            // Is there an out appointment
            if (response.data.hasOutAppointment === 1) {
              this.newWorkdayModal = false;
              this.workdayModalNotification = true;
            } else {
              this.updateOrCreateWorkday();
            }
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    async updateOrCreateWorkday() {
      await axios
        .post(this.$store.getters.getApiUrl + "workday/createOrUpdate", {
          token: this.$store.getters.getToken,
          id: this.workDay.id,
          currentDate: this.currentDate,
          timeStart: this.workDay.time_start,
          timeStop: this.workDay.time_stop,
        })
        .then((response) => {
          if (response.status === 200) {
            // this.workDay = response.data.workday;
            this.workdayNotifier = response.data.workdayNotifier;
            this.newWorkdayModal = false;
            this.attributes = response.data.attributes;

            //Set workday data
            //Init workday id for update or for create
            if (response.data.workDay && response.data.workDay.id) {
              this.workDay.id = response.data.workDay.id;
              this.workDay.time_start = response.data.workDay.time_start;
              this.workDay.time_stop = response.data.workDay.time_stop;
              this.workDay.time_start_show =
                response.data.workDay.time_start_show;
              this.workDay.time_stop_show =
                response.data.workDay.time_stop_show;
            } else {
              this.workDay.id = 0;
            }

            //Close notification modal
            this.workdayModalNotification = false;

            //Reload today appointment data
            this.refreshDay();
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    addNewContact(contact) {
      axios
        .post(this.$store.getters.getApiUrl + "contact/store/registered", {
          token: this.$store.getters.getToken,
          contact_user_id: contact.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.in_contact = response.data.in_contact;
            this.detailsModal = false;

            //Refresh the current date datas
            this.refreshDay();

            //Show a toaster with success
            this.$toasted.show("Sikeres hozzáadás!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    async syncDateTime(date = null, time = null, type = "start", from = null) {
      this.timeSync = false;

      await axios
        .post(this.$store.getters.getApiUrl + "time/sync", {
          token: this.$store.getters.getToken,
          date: date,
          time: time,
          from: from,
        })
        .then((response) => {
          if (response.status === 200) {
            //FIXME: disable console.log
            // console.log("------------");
            // console.log("Time sync running...");
            // console.log("Time sync API resp: ", response.data);

            if (type == "start") {
              //   this.workDay.time_start = "lofasz";
              this.workDay.time_start = response.data.time.dateTime;
              this.workDay.time_start_show = response.data.time.time;
            }

            if (type == "stop") {
              this.workDay.time_stop = response.data.time.dateTime;
              this.workDay.time_stop_show = response.data.time.time;
            }

            //FIXME: disable console.log
            // console.log("Workday time synced: ", this.workDay);
            // console.log("------------");

            setTimeout(() => {
              this.timeSync = true;
            }, 150);
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    openDateTime(element) {
      if (element == "start") {
        this.$refs.dateTimeStart.isOpen = true;
      }

      if (element == "stop") {
        this.$refs.dateTimeStop.isOpen = true;
      }
    },
    updateCalendar(calendar) {
      axios
        .post(this.$store.getters.getApiUrl + "calendar/getByMonth", {
          token: this.$store.getters.getToken,
          month: calendar.month,
          year: calendar.year,
          day: this.currentDate,
        })
        .then((response) => {
          //Init attributes to null
          this.attributes = {
            key: "today",
            highlight: true,
            dates: new Date(),
          };

          //When the response is 200, we set the calendar attributes
          if (response.status === 200) {
            this.attributes = response.data.attributes;
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
    openWorkdayModal() {
      //Open modal
      this.newWorkdayModal = true;
      //Close fab
      this.showButtonOptions = false;

      //Sync workday main times
      setTimeout(() => {
        //Start time
        this.syncDateTime(
          this.currentDate,
          this.workDay.time_start_show,
          "start"
        );

        //Stop time
        this.syncDateTime(
          this.currentDate,
          this.workDay.time_stop_show,
          "stop"
        );
      }, 250);
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    // attributes: function() {
    // console.log("attributes changed");
    // }
    // newWorkdayModal() {
    //   if (this.newWorkdayModal === true) {
    //     let tmpTime = {
    //       id: this.workDay.id,
    //       time_start: this.workDay.time_start_show,
    //       time_stop: this.workDay.time_stop_show,
    //     };
    //     console.log("new Workday modal open! - ", tmpTime);
    //     setTimeout(() => {
    //       this.setWorkdayData(this.currentDate, tmpTime);
    //     }, 200);
    //   }
    // },
    "workDay.time_start_show"() {
      if (
        this.workDay.time_start_show &&
        this.workDay.time_start_show.length > 4 &&
        this.timeSync
      ) {
        //Get synced time
        this.syncDateTime(
          this.currentDate,
          this.workDay.time_start_show,
          "start"
        );
      }
    },
    "workDay.time_stop_show"() {
      if (
        this.workDay.time_stop_show &&
        this.workDay.time_stop_show.length > 4 &&
        this.timeSync
      ) {
        //Get synced time
        this.syncDateTime(
          this.currentDate,
          this.workDay.time_stop_show,
          "stop"
        );
      }
    },
    "workDay.time_start"() {
      if (
        this.workDay.time_start &&
        this.workDay.time_start.length > 1 &&
        this.timeSync
      ) {
        //Get synced time
        setTimeout(() => {
          this.syncDateTime(
            this.currentDate,
            this.workDay.time_start,
            "start",
            "chooser"
          );
        }, 100);
      }
    },
    "workDay.time_stop"() {
      if (
        this.workDay.time_stop &&
        this.workDay.time_stop.length > 1 &&
        this.timeSync
      ) {
        //Get synced time
        this.syncDateTime(
          this.currentDate,
          this.workDay.time_stop,
          "stop",
          "chooser"
        );
      }
    },
  },
  mounted() {
    //Init calendar & other main datas
    this.getAppointmentsForCalendar();
  },
};
</script>
