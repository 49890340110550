var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"appHeader text-dark",class:_vm.customClass},[_c('div',{staticClass:"left"},[(_vm.backRouteName)?_c('a',{staticClass:"headerButton",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#sidebarPanel"},on:{"click":function($event){return _vm.goBackTo()}}},[_c('div',{staticClass:"icon-custom icon-chevron-back-white"})]):_c('a',{staticClass:"headerButton text-primary",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#sidebarPanel"},on:{"click":_vm.updateMenuState}},[_c('div',{staticClass:"icon-custom icon-menu"}),(
            _vm.notificationsCounter > 0 ||
            (_vm.contactCounter > 0 && _vm.notificationsCounter + _vm.contactCounter < 10)
          )?_c('span',{staticClass:"badge badge-pill badge-active"},[_vm._v(_vm._s(_vm.notificationsCounter + _vm.contactCounter)+" ")]):_vm._e(),(
            (_vm.notificationsCounter > 0 || _vm.contactCounter > 0) &&
            _vm.notificationsCounter + _vm.contactCounter >= 10
          )?_c('span',{staticClass:"badge badge-pill badge-active"},[_vm._v("9+ ")]):_vm._e()])]),_c('div',{staticClass:"pageTitle text-center"},[_vm._v(" "+_vm._s(_vm.transparent ? "" : _vm.title)+" "),(_vm.muted)?_c('div',{staticClass:"small muted"},[_vm._v(_vm._s(_vm.muted))]):_vm._e()]),(!_vm.backRouteName && !_vm.transparent)?_c('div',{staticClass:"right"},[_c('a',{staticClass:"headerButton toggle-searchbox text-primary",attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.searchBar = true}}},[_c('div',{staticClass:"icon-custom icon-search-top"})])]):_vm._e()]),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animated slideInDown","leave-active-class":"animated slideOutUp","duration":750}},[(_vm.searchBar)?_c('div',{staticClass:"appHeader show",attrs:{"id":"search"}},[_c('form',{staticClass:"search-form",on:{"submit":_vm.goToSearchWithQuery}},[_c('div',{staticClass:"form-group searchbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Milyen szolgáltatást keresel?"},domProps:{"value":(_vm.searchQuery)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.goToSearchWithQuery.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}}),_c('div',{staticClass:"input-icon-custom icon-custom icon-search-top"}),_c('a',{staticClass:"ml-1 close toggle-searchbox text-primary",attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.searchBar = false}}},[_c('div',{staticClass:"icon-custom icon-close-circle-gold"})])])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }