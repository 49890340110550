var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section inset"},_vm._l((_vm.shops),function(sp,index){return _c('div',{key:index,staticClass:"wide-block bg-white bg-tarnsparent-95 pt-2 pb-2 mt-1",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$router.push({ name: 'SearchShopServices', params: { id: sp.id } })}}},[_c('div',{staticClass:"content-header mb-05 text-black-100"},[_vm._v(" "+_vm._s(sp.zip)+" "+_vm._s(sp.city)+", "+_vm._s(sp.address)+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"avatar"},[(sp.profile_image)?_c('div',{staticClass:"profileImage",style:({
              backgroundImage: 'url(' + sp.profile_image + ')',
            })}):_c('div',{staticClass:"profileImage",style:({
              backgroundImage:
                'url(' + require('@/assets/img/profile.jpg') + ')',
            })})])]),_c('div',{staticClass:"col-9"},[_vm._v(" "+_vm._s(sp.name)+" "),_vm._m(0,true),_c('div',{staticClass:"content-footer mt-05 text-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 text-left text-black-100"},[_vm._v(" Tel: "+_vm._s(sp.phone)+" "),_c('br'),_vm._v(" Email: "+_vm._s(sp.email)+" ")]),_c('div',{staticClass:"col-6 text-right"})])])])])])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-inline-block text-primary text-right float-right",staticStyle:{"font-size":"2rem","margin-top":"-1.5rem"}},[_c('div',{staticClass:"icon-custom icon-chevron-forward-circle-outline"})])
}]

export { render, staticRenderFns }