<template>
  <div>
    <RegistrationHeaderCustomer />

    <div class="section mb-2 mt-2 full">
      <div class="wide-block pt-2 pb-2">
        <form id="form1" action="#" enctype="multipart/form-data" method="post">
          <div class="picture-container">
            <div class="picture">
              <img
                id="wizardPicturePreview"
                class="picture-src"
                :src="registration.profileImage"
                title="profil kép"
                onclick="document.getElementById('wizard-picture').click()"
              />
              <input
                id="wizard-picture"
                class=""
                name="profilePhoto"
                type="file"
                v-on:change="refreshProfileImage"
              />
              <div class="fab-button animate profilePhotoEdit">
                <div
                  id="profilePhotoEditBtn"
                  class="fab profilePhotoEditButton"
                >
                  <ion-icon
                    aria-label="add outline"
                    class=""
                    name="camera-outline"
                    role="img"
                  ></ion-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                id="phone1"
                name="phone"
                placeholder="Telefonszám"
                v-model="registration.phone"
                :class="{ 'is-invalid': errors.phone }"
              />
              <div class="invalid-feedback" v-if="errors.phone">
                {{ errors.phone[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="zip"
                placeholder="Irányítószám"
                v-model="registration.zip"
                :class="{ 'is-invalid': errors.zip }"
              />
              <div class="invalid-feedback" v-if="errors.zip">
                {{ errors.zip[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="city"
                placeholder="Város"
                v-model="registration.city"
                :class="{ 'is-invalid': errors.city }"
              />
              <div class="invalid-feedback" v-if="errors.city">
                {{ errors.city[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="address"
                placeholder="Cím"
                v-model="registration.address"
                :class="{ 'is-invalid': errors.address }"
              />
              <div class="invalid-feedback" v-if="errors.address">
                {{ errors.address[0] }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-7 text-left pl-3 regOldEnough">
              <div class="alert alert-warning mb-2" role="alert">
                Kijelentem, hogy elmúltam 18 éves
              </div>
            </div>
            <div class="col-4 text-right">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="oldEnough"
                  id="type"
                />
                <label class="custom-control-label" for="type"></label>
              </div>
            </div>
          </div>

          <div class="wide-block pt-2 pb-2">
            <div class="alert alert-warning mb-2" role="alert">
              A regisztrációval elfogadod a <br />
              <a href="https://fixeet.com/adatkezelesi-tajekoztato/">
                Felhasználási feltételeket
              </a>
            </div>
          </div>

          <div class="form-button-group">
            <div class="btn btn-magenta btn-lg btn-block" @click="checkForm">
              Befejezés
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "@/assets/js/lib/jquery-3.4.1.min";
import RegistrationHeaderCustomer from "@/partials/registration/RegistrationHeaderCustomer";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "CustomerStep2",
  data: () => ({
    registration: {
      profileImage: "../assets/img/profile.jpg",
      phone: "",
      zip: "",
      city: "",
      address: "",
      oldEnough: false,
      registrationInProgress: false,
    },
    errors: {},
    uploadedFile: "",
  }),
  components: {
    RegistrationHeaderCustomer,
  },
  methods: {
    checkForm() {
      this.errors = "";

      let data = new FormData();
      data.append("email", this.registration.email);
      data.append("name", this.registration.name);
      data.append("password", this.registration.password);
      data.append("zip", this.registration.zip);
      data.append("city", this.registration.city);
      data.append("address", this.registration.address);
      data.append("phone", this.registration.phone);
      data.append("uploadedFile", this.uploadedFile);

      //Check old enough
      if (!this.oldEnough) {
        //Show error message
        this.$toasted.show("Nem fogadtad el az összes feltételt!", {
          position: "bottom-center",
          duration: 2500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
        });

        return;
      }

      //If registration not in progress
      if (!this.registrationInProgress) {
        //Set registration in progress
        this.registrationInProgress = true;

        axios
          .post(
            this.$store.getters.getApiUrl + "registration/customer_step2",
            data
          )
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("loginSuccess", {
                user: response.data.user,
              });

              //Save email & password to localStorage
              localStorage.loginEmail = this.$data.registration.email;
              localStorage.loginPassword = this.$data.registration.password;

              this.$router.push({ name: "Dashboard" });
            }
          })
          .catch((error) => {
            this.$store.commit("addErrorMessage", {
              message: error.response.data.message,
              timeout: 2500,
            });
          });
      }
    },
    refreshProfileImage(e) {
      const file = e.target.files[0];
      this.registration.profileImage = URL.createObjectURL(file);
      this.uploadedFile = file;
    },
  },
  mounted() {
    this.$store.state.registration.step = 2;
    this.$data.registration = this.$store.getters.getRegistrationData;

    //Add bg class to body
    document.body.classList.add("registrationBgCustomer");
  },
  destroyed() {
    //Remove bg class to body
    document.body.classList.remove("registrationBgCustomer");
  },
};
</script>
