var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgDashboard mainDashboard disableYPadding",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col-1 text-left"},[_c('div',{staticClass:"icon-custom icon-menu-white",on:{"click":_vm.updateMenuState}}),(
          (_vm.notificationsCounter > 0 || _vm.contactCounter > 0) &&
          _vm.notificationsCounter + _vm.contactCounter >= 10
        )?_c('span',{staticClass:"badge badge-pill badge-active badge-dashboard"},[_vm._v("9+ ")]):(
          _vm.notificationsCounter > 0 ||
          (_vm.contactCounter > 0 && _vm.notificationsCounter + _vm.contactCounter < 10)
        )?_c('span',{staticClass:"badge badge-pill badge-active badge-dashboard"},[_vm._v(_vm._s(_vm.notificationsCounter + _vm.contactCounter)+" ")]):_vm._e()])]),_c('div',{staticClass:"header-title dashboardTitle p-3"},[(_vm.loggedIn)?_c('h3',{staticClass:"text-light"},[_vm._v("Üdv újra a CleanCar App-ban!")]):_c('h3',{staticClass:"text-light"},[_vm._v("Üdvözöllek a CleanCar App-ban!")]),_vm._m(0)]),(_vm.loggedIn && _vm.user.email_verified == 0)?_c('div',{staticClass:"section full my-3 text-center"},[_vm._m(1)]):_vm._e(),_c('Categories'),_c('Nav'),_c('div',{staticClass:"section full mb-3 text-center"},[_c('h3',{staticClass:"text-light"},[_vm._v("🧭 Térkép 🧭")]),_c('div',{staticClass:"wide-block"},[_c('div',{staticClass:"card"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"300px","margin-bottom":"2rem"},attrs:{"center":{lat:46.912868, lng:19.050293},"options":{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      },"zoom":7,"map-type-id":"terrain"}},_vm._l((_vm.markers),function(m,index){return _c('GmapCustomMarker',{key:index,attrs:{"marker":m.position}},[_c('img',{attrs:{"src":require('@/assets/img/gmap-pin-cca-xs3.png')}})])}),1)],1)])]),_c('NavFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subtitle dashboardSubtitle"},[_vm._v(" Találd meg a kedvenc autómosód és foglalj időpontot akár "),_c('strong',[_vm._v("30 mp")]),_vm._v(" alatt! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wide-block"},[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_c('h4',{staticClass:"alert-title"},[_vm._v("😮 Fiókod még nincs aktiválva 🤔")])])])
}]

export { render, staticRenderFns }