import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import FromNotification from "../views/FromNotification";
import Registration from "../views/Registration.vue";
import CustomerStep1 from "../views/registration-steps/CustomerStep1.vue";
import CustomerStep2 from "../views/registration-steps/CustomerStep2.vue";
import ServiceProviderStep1 from "../views/registration-steps/ServiceProviderStep1.vue";
import ServiceProviderStep2 from "../views/registration-steps/ServiceProviderStep2.vue";
import ServiceProviderStep3 from "../views/registration-steps/ServiceProviderStep3.vue";
import ServiceProviderStep4 from "../views/registration-steps/ServiceProviderStep4.vue";
import NotFoundComponent from "../views/errors/404.vue";
import Dashboard from "../views/Dashboard";
import Settings from "../views/Settings";
import Shop from "../views/Shop";
import ShopUsers from "../views/ShopUsers";
import Search from "../views/Search";
import Category from "../views/Category";
import Service from "../views/Service";
import MyServices from "../views/MyServices";
import MyServiceEdit from "../views/MyServiceEdit";
import MyServiceNew from "../views/MyServiceNew";
import ServiceProviderAppointments from "../views/ServiceProviderAppointments";
import ServiceProviderAppointmentEdit from "../views/ServiceProviderAppointmentEdit";
import ServiceProviderAppointmentNew from "../views/ServiceProviderAppointmentNew";
import Calendar from "../views/Calendar";
import Notification from "../views/Notification";
import ServiceProviderCalendar from "../views/ServiceProviderCalendar";
import ServiceProviderCalendarNewAppointment from "../views/ServiceProviderCalendarNewAppointment";
import Faq from "@/views/Faq";
import Messages from "@/views/Messages";
import Contacts from "@/views/Contacts";
import SearchSPServices from "@/views/SearchSPServices";
import SearchShopServices from "@/views/SearchShopServices";
import SearchShopServicesEmployees from "@/views/SearchShopServicesEmployees";
import ServiceProviderAppointmentServices from "@/views/ServiceProviderAppointmentServices";
import MessagesShow from "@/views/MessagesShow";
import QR_Code from "@/views/QR_Code";
import QR_CodeScanner from "@/views/QR_CodeScanner";
import MassWorkday from "@/views/workday/MassWorkday";
import MassWorkdayFix from "@/views/workday/MassWorkdayFix";
import MassWorkdayRepeatly from "@/views/workday/MassWorkdayRepeatly";
import MassWorkdayDelete from "@/views/workday/MassWorkdayDelete";
import PasswordRecovery from "@/views/PasswordRecovery";

Vue.use(VueRouter);

const routes = [
  // Def HOME - LOGIN
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard
  },
  //LOGIN
  {
    path: "/login",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Home
  },
  {
    path: "/password-recovery",
    name: "PasswordRecovery",
    component: PasswordRecovery
  },
  // From notification
  {
    path: "/fromNotification",
    name: "FromNotification",
    component: FromNotification
  },
  // REGISTRATION & STEPS
  {
    path: "/registration",
    name: "Registration",
    component: Registration
  },
  {
    path: "/registration/customer/step1",
    name: "CustomerStep1",
    component: CustomerStep1
  },
  {
    path: "/registration/customer/step2",
    name: "CustomerStep2",
    component: CustomerStep2
  },
  {
    path: "/registration/service-provider/step1",
    name: "ServiceProviderStep1",
    component: ServiceProviderStep1
  },
  {
    path: "/registration/service-provider/step2",
    name: "ServiceProviderStep2",
    component: ServiceProviderStep2
  },
  {
    path: "/registration/service-provider/step3",
    name: "ServiceProviderStep3",
    component: ServiceProviderStep3
  },
  {
    path: "/registration/service-provider/step4",
    name: "ServiceProviderStep4",
    component: ServiceProviderStep4
  },
  //AUTH ROUTES
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
    // beforeEnter: ifAuthenticated
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop
  },
  {
    path: "/shop/users",
    name: "ShopUsers",
    component: ShopUsers
  },
  {
    path: "/category/:id",
    name: "Category",
    component: Category
  },
  {
    path: "/calendar/",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/sp/calendar/",
    name: "ServiceProviderCalendar",
    component: ServiceProviderCalendar
  },
  {
    path: "/sp/calendar/newAppointment/",
    name: "ServiceProviderCalendarNewAppointment",
    component: ServiceProviderCalendarNewAppointment
  },
  {
    path: "/sp/calendar/massWorkday",
    name: "MassWorkday",
    component: MassWorkday
  },
  {
    path: "/sp/calendar/massWorkday/fix",
    name: "MassWorkdayFix",
    component: MassWorkdayFix
  },
  {
    path: "/sp/calendar/massWorkday/repeatly",
    name: "MassWorkdayRepeatly",
    component: MassWorkdayRepeatly
  },
  {
    path: "/sp/calendar/massWorkday/delete",
    name: "MassWorkdayDelete",
    component: MassWorkdayDelete
  },
  {
    path: "/contacts/",
    name: "Contacts",
    component: Contacts
  },
  {
    path: "/service/:employeeId/:serviceId",
    name: "Service",
    component: Service
  },
  {
    path: "/sp/service/:id",
    name: "MyServices",
    component: MyServices
  },
  {
    path: "/sp/service/edit/:id",
    name: "MyServiceEdit",
    component: MyServiceEdit
  },
  {
    path: "/sp/service/new/",
    name: "MyServiceNew",
    component: MyServiceNew
  },
  {
    path: "/sp/appointment/byService",
    name: "ServiceProviderAppointmentServices",
    component: ServiceProviderAppointmentServices
  },
  {
    path: "/sp/appointment/",
    name: "ServiceProviderAppointments",
    component: ServiceProviderAppointments
  },
  {
    path: "/sp/appointment/:service_id/edit/:id",
    name: "ServiceProviderAppointmentEdit",
    component: ServiceProviderAppointmentEdit
  },
  {
    path: "/sp/appointment/new/",
    name: "ServiceProviderAppointmentNew",
    component: ServiceProviderAppointmentNew
  },
  {
    path: "/notifications",
    name: "Notification",
    component: Notification
  },
  {
    path: "/qr_code",
    name: "QR_Code",
    component: QR_Code
  },
  {
    path: "/qr_code/scan",
    name: "QR_CodeScanner",
    component: QR_CodeScanner
  },
  {
    path: "/gyik",
    name: "Faq",
    component: Faq
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages
  },
  {
    path: "/messages/:id/show",
    name: "MessagesShow",
    component: MessagesShow
  },
  {
    path: "/search/:search?",
    name: "Search",
    component: Search
  },
  {
    path: "/search/shop/:id/:back?",
    name: "SearchShopServices",
    component: SearchShopServices
  },
  {
    path: "/search/shop/employees/:id",
    name: "SearchShopServicesEmployees",
    component: SearchShopServicesEmployees
  },
  {
    path: "/search/serviceProvider/:id",
    name: "SearchSPServices",
    component: SearchSPServices
    // beforeEnter: ifAuthenticated
  },
  { path: "*", component: NotFoundComponent }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// const ifAuthenticated = (to, from, next) => {
//   if (this.$store.getters.getToken) {
//     next();
//     return;
//   }
//   router.push({
//     name: "Home",
//     params: {
//       returnTo: to.path,
//       query: to.query
//     }
//   });
// };

export default router;
