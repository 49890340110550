<template>
  <div>
    <Auth />

    <Header title="Beállítások" back-route-name="Dashboard" />

    <div class="section mt-2">
      <form id="form1" action="#" enctype="multipart/form-data" method="post">
        <div class="picture-container">
          <div class="picture mb-2">
            <img
              id="wizardPicturePreview"
              class="picture-src"
              :src="user.profileImage"
              title="profil kép"
              onclick="document.getElementById('wizard-picture').click()"
            />
            <input
              id="wizard-picture"
              class=""
              name="profileImage"
              type="file"
              accept="image/*"
              v-on:change="refreshProfileImage"
            />
            <div class="fab-button animate profilePhotoEdit">
              <div id="profilePhotoEditBtn" class="fab profilePhotoEditButton">
                <ion-icon
                  aria-label="add outline"
                  class=""
                  name="camera-outline"
                  role="img"
                ></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              placeholder="Név"
              v-model="user.name"
              :class="{ 'is-invalid': errors.name }"
            />
            <div class="invalid-feedback" v-if="errors.name">
              {{ errors.name[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="email"
              class="form-control disabled"
              id="email1"
              name="email"
              placeholder="Email cím"
              v-model="user.email"
              :class="{ 'is-invalid': errors.email }"
              disabled
            />
            <div class="invalid-feedback" v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="password"
              class="form-control"
              id="password1"
              name="password"
              placeholder="Jelszó"
              v-model="user.password"
              :class="{ 'is-invalid': errors.password }"
            />
            <div class="invalid-feedback" v-if="errors.password">
              {{ errors.password[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="text"
              class="form-control"
              id="phone"
              name="phone"
              placeholder="Telefonszám"
              v-model="user.phone"
              :class="{ 'is-invalid': errors.phone }"
            />
            <div class="invalid-feedback" v-if="errors.phone">
              {{ errors.phone[0] }}
            </div>
          </div>
        </div>

        <!--<ul class="listview simple-listview">-->
        <!--    <li>-->
        <div v-show="user.type != 0" class="row my-3 text-light">
          <div class="col-8">
            <div class="ml-1">
              <strong>Privát naptár nézet</strong> <br />
              <div class="settingsDescription">
                Bekapcsolás esetén csak kapcsolatban lévő személy láthatja a
                szabad időpontokat
              </div>
            </div>
          </div>
          <div class="col-4 text-right">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="user.private_calendar"
                id="privateCalendar"
              />
              <label class="custom-control-label" for="privateCalendar"></label>
            </div>
          </div>
        </div>

        <div class="row my-3 text-light">
          <div class="col-8">
            <div class="ml-1">
              <strong>Értesítések időzítése</strong> <br />
              <div class="settingsDescription">
                Megadhatod, hogy az foglalásaid előtt, mennyi idővel kapj
                értesítést.
              </div>
            </div>
          </div>
          <div class="col-4">
            <div
              @click="showReminderModal = true"
              class="btn btn-magenta btn-md btn-block my-2"
            >
              {{ user.reminderTime }} {{ user.reminderUnit }}
            </div>
          </div>
        </div>

        <div class="row my-3 text-light">
          <div class="col-8">
            <div class="ml-1">
              <strong>Fiók törlése</strong> <br />
              <div class="settingsDescription">
                Fiókod végleges törlése, minden adatoddal együtt.
              </div>
            </div>
          </div>
          <div class="col-4">
            <div
              @click="showDeletionModal = true"
              class="btn btn-magenta btn-md btn-block my-2"
            >
              Törlés
            </div>
          </div>
        </div>

        <!--    </li>-->
        <!--</ul>-->

        <div
          @click="saveNewData"
          class="btn btn-magenta btn-lg btn-block my-2 mb-5"
        >
          Mentés
        </div>
      </form>
    </div>
    <Nav />
    <NavFooter />

    <!-- Modals -->
    <!-- Reminder edit modal -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    >
      <div
        v-if="showReminderModal"
        class="modal fade panelbox panelbox-right show"
        id="PanelRight"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Értesítések időzítése</h4>
              <div

                @click="closeReminderModal"
                data-dismiss="modal"
                class="panel-close"
              >
                <!-- <ion-icon name="close-outline" role="img" class="md hydrated" aria-label="close outline"></ion-icon> -->
                <div class="icon-custom icon-close-circle"></div>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <p class="">
                    Itt megadhatod mennyivel előbb kapj értesítést a
                    foglalásaidról, időpontjaidról. <br />
                  </p>
                </div>
                <div class="col-4">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <input
                        type="text"
                        class="form-control"
                        name="reminderTime"
                        id="reminderTime"
                        v-model="user.reminderTime"
                        :class="{ 'is-invalid': errors.reminderTime }"
                      />
                      <div class="invalid-feedback" v-if="errors.reminderTime">
                        {{ errors.reminderTime[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8 text-center">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <select
                        type="text"
                        class="form-control"
                        name="reminderUnit"
                        id="reminderUnit"
                        v-model="user.reminderUnit"
                        :class="{ 'is-invalid': errors.reminderUnit }"
                      >
                        <option value="perc">perc</option>
                        <option value="óra">óra</option>
                        <option value="nap">nap</option>
                      </select>
                      <div class="invalid-feedback" v-if="errors.reminderUnit">
                        {{ errors.reminderUnit[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-8 offset-2">
                  <div
                    @click="saveReminderData"
                    class="btn btn-magenta btn-md btn-block my-2"
                  >
                    Mentés
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Delete modal -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    >
      <div
        v-if="showDeletionModal"
        class="modal fade panelbox panelbox-right show"
        id="PanelRight"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Fiók végleges törlése</h4>
              <div
                @click="closeDeletionModal"
                data-dismiss="modal"
                class="panel-close"
              >
                <!-- <ion-icon name="close-outline" role="img" class="md hydrated" aria-label="close outline"></ion-icon> -->
                <div class="icon-custom icon-close-circle"></div>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <p class="">
                    Itt véglegesen törölheted fiókod. <br />
                    A törléssel minden időpontod, szolgáltatásod, üzeneteid
                    elvesznek. Törlés után nem tudjuk visszaállítani fiókod.
                  </p>
                </div>
                <div v-if="user.type == 1 && users.length > 1" class="col-12">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label for="deletePassword">
                        Jogosultságod át kell ruháznod
                      </label>
                      <select
                        type="text"
                        class="form-control"
                        name="newAdmin"
                        id="newAdmin"
                        v-model="newAdmin"
                        :class="{ 'is-invalid': errors.newAdmin }"
                      >
                        <option
                          v-for="(user, index) in users"
                          :key="index"
                          :value="user.id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                      <div class="invalid-feedback" v-if="errors.newAdmin">
                        {{ errors.newAdmin[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label for="deletePassword">
                        A törléshez add meg jelszavad
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="deletePassword"
                        id="deletePassword"
                        v-model="deletePassword"
                        :class="{ 'is-invalid': errors.deletePassword }"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="errors.deletePassword"
                      >
                        {{ errors.deletePassword[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-8 offset-2">
                  <div
                    @click="deleteAccountCheck()"
                    class="btn btn-magenta btn-md btn-block my-2"
                  >
                    Törlés
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div
      v-show="showReminderModal || showDeletionModal"
      class="modal-backdrop fade show"
    ></div>
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
import { mapGetters } from "vuex";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Settings",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    // user: {},
    users: {},
    errors: {},
    deletePassword: "",
    newAdmin: "",
    uploadedFile: "",
    showReminderModal: false,
    showDeletionModal: false
  }),
  methods: {
    saveNewData() {
      this.errors = "";

      let data = new FormData();
      data.append("email", this.user.email);
      data.append("name", this.user.name);
      data.append("password", this.user.password);
      data.append("phone", this.user.phone);
      data.append("uploadedFile", this.uploadedFile);
      data.append("type", this.user.type);
      data.append("token", this.user.token);
      data.append("private_calendar", this.user.private_calendar);

      axios
        .post(this.$store.getters.getApiUrl + "user/update", data)
        .then((response) => {
          //   if (response.data && response.data.success) {

          //Toast success message
          this.$toasted.show("Sikeres mentés!", {
            position: "bottom-center",
            duration: 3500,
            fullWidth: true,
            fitToScreen: false,
            type: "success",
            // icon: "checkmark-outline"
          });

          this.$store.commit("loginSuccess", {
            user: response.data.user,
          });

          //Save email & password to localStorage
          localStorage.loginEmail = this.$data.user.email;
          localStorage.loginPassword = this.$data.user.password;

          //   }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.errors = error.response.data.errors;
          }
        });
    },
    refreshProfileImage(e) {
      const file = e.target.files[0];
      this.user.profileImage = URL.createObjectURL(file);
      this.uploadedFile = file;

      //Upload profile photo
      let data = new FormData();
      data.append("uploadedFile", this.uploadedFile);
      data.append("token", this.user.token);

      axios
        .post(this.$store.getters.getApiUrl + "user/update/profilePhoto", data)
        .then((response) => {
          if (response.data && response.data.success) {
            //Toast success message
            this.$toasted.show("Sikeres profilkép mentés!", {
              position: "bottom-center",
              duration: 3500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.errors = error.response.data.errors;
          }
        });
    },
    saveReminderData() {
      this.errors = "";

      axios
        .post(this.$store.getters.getApiUrl + "user/updateReminder", {
          token: this.$store.getters.getToken,
          reminderTime: this.user.reminderTime,
          reminderUnit: this.user.reminderUnit,
        })
        .then((response) => {
          if (response.status === 200) {
            //Init settings
            this.showReminderModal = false;
            this.$store.commit("loginSuccess", {
              user: response.data.user,
            });

            //Toast success message
            this.$toasted.show("Sikeres mentés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          //   this.errors = error.response.data.errors;
        });
    },
    deleteAccountCheck() {
      this.errors = "";

      //Check user is a normal user or a serviceProvider
      if (this.user.type > 0) {
        //Check there is no employee in shop
        if (this.users.length <= 1) {
          console.log("There is no employee in shop");
          //return;
          this.deleteAccount();
        } else {
          //If there is more employee in shop
          if (this.newAdmin <= 0 || this.newAdmin == this.user.id) {
            console.log("You need to switch account role!");
            //Toast success message
            this.$toasted.show("Jogosultságot át kell ruházni!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "error",
            });
            return;
          } else {
            this.deleteAccount();
          }
        }
      } else {
        //Fire deletion event, because normal user or employee
        this.deleteAccount();
      }
    },
    deleteAccount() {
      axios
        .post(this.$store.getters.getApiUrl + "user/deleteAccount", {
          token: this.$store.getters.getToken,
          deletePassword: this.deletePassword,
          newAdmin: this.newAdmin,
        })
        .then((response) => {
          if (response.status === 200) {
            //Init settings
            localStorage.token = null;
            localStorage.loginEmail = null;
            localStorage.loginPassword = null;
            localStorage.currentUser = null;
            localStorage.userId = null;

            //Log out
            this.$store.commit("updateLoginStatus", { loggedIn: false });

            //Redirect home
            this.$router.push({ name: "Home" });

            //Toast success message
            this.$toasted.show("Sikeres fiók törlés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "success"
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          //   this.errors = error.response.data.errors;

          //   if (error.response.status === 500) {
          //     //Toast error message
          //     this.$toasted.show("Hibás jelszó!", {
          //       position: "bottom-center",
          //       duration: 2500,
          //       fullWidth: true,
          //       fitToScreen: true,
          //       type: "error",
          //     });
          //   }
        });
    },
    getUsers() {
      axios
        .post(this.$store.getters.getApiUrl + "shop/users", {
          token: this.$store.getters.getToken
        })
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.users;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          if (error.response.status === 500) {
            //Toast error message
            this.$toasted.show("Hibás jelszó!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "error"
            });
          }
        });
    },
    closeDeletionModal() {
      console.log('asdasd');
      this.showDeletionModal = false;
      this.deletePassword = "";
      this.newAdmin = "";
    },
    closeReminderModal() {
      this.showReminderModal = false;
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  mounted() {
    this.getUsers();
  },
};
</script>
