<template>
  <div>
    <div class="wide-block pt-2 pb-2">
      <div v-if="loader" class="row">
        <div class="col-12 align-content-center justify-content-center">
          <div class="mx-auto" style="height: 5rem; width: 5rem">
            <lottie-animation
              ref="anim"
              :animationData="require('@/assets/img/lottie/loader_ccacar.json')"
              :loop="true"
            />
          </div>
        </div>
      </div>
      <div v-show="!loader" :class="classLabel" role="alert">
        <h4 class="alert-title">
          {{ title }}
        </h4>
        {{ message }}
        <br />
        {{ message2 }}
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LottieAnimation from "lottie-web-vue";

export default {
  name: "ErrorPanel",
  data: () => ({
    loader: true,
  }),
  components: {
    LottieAnimation,
  },
  computed: {},
  props: {
    title: String,
    message: String,
    message2: String,
    classLabel: String,
  },
  methods: {},
  mounted() {
    this.loader = true;
    let v = this;
    setTimeout(function () {
      v.$data.loader = false;
    }, 2000);
  }
};
</script>
